<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="ponto_equilibrio_columns"
        :items="ponto_equilibrio.data"
        :primaryKey="ponto_equilibrio.primaryKey"
        :sortBy="ponto_equilibrio.sortBy"
      >
        <template #custom-foot>
          <tr
            class="bg-primary text-white"
            v-if="ponto_equilibrio.foot_data[0]"
          >
            <td>Ponto de Equilíbrio</td>
            <td class="text-center">
              {{ ponto_equilibrio.foot_data[0].simbolo_moeda }}
            </td>
            <template v-for="(ano, index) in anos">
              <td :key="'td-anos-3-' + index" class="text-right">
                {{
                  formatCurrency(
                    ponto_equilibrio.foot_data[0][
                      "ponto_equilibrio_total_ano_" + ano
                    ]
                  )
                }}
              </td>
            </template>
          </tr>
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipPontoEquilibrioList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-random",
        title: "Ponto de Equilíbrio",
        description: `O ponto de equilíbrio é um indicador de segurança do negócio,
          pois mostra o quanto é necessário vender para que as receitas se igualem
          aos custos. Ele indica em que momento, a partir das projeções de vendas,
          a empresa estará igualando suas receitas e seus custos, eliminando assim
          a possibilidade de prejuízo em sua operação. O quadro do ponto de equilíbrio
          apresenta o valor anual da receita necessária para cobrir os custos e as
          quantidades de cada produto que necessitam ser vendidas.`
      },
      ponto_equilibrio: {
        data: [],
        foot_data: [],
        primaryKey: "idpip_produto",
        sortBy: "descricao"
      },
      simbolo_moeda: "",
      anos: 0,
      tipo_plano_investimento: null
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/ponto_equilibrio`;
    },
    manual() {
      return {
        session: "ponto_equilibrio",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    ponto_equilibrio_columns() {
      let columns = [
        {
          key: "descricao",
          label: "Descrição",
          sortable: true
        },
        {
          key: "nome_unidade_medida",
          label: "Unidade",
          sortable: true,
          tdClass: "text-center"
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "quantidade_total_ano_" + ano,
          label: "Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "number"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.ponto_equilibrio.data = res[R_GETLIST].produtos;
        this.ponto_equilibrio.foot_data = res[R_GETLIST].total;
        this.anos = res[R_GETLIST].anos;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
